import ApplicationController from './application_controller'

export default class extends ApplicationController {
  clean() {
    let searchBox = document.getElementById('search')
    let event = new Event('debounced:input')
    search.value = ''
    search.dispatchEvent(event)
  }
}
