import ApplicationController from '../application_controller'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import './tooltip.scss'
import 'tippy.js/animations/scale.css'

export default class extends ApplicationController {
  connect() {
    super.connect()
    tippy('[data-tippy-content]', {
      maxWidth: 155,
      theme: 'mailist',
      allowHTML: true,
      interactive: true
    })
  }
}
