import ApplicationController from './application_controller'

export default class extends ApplicationController {
  paginateSuccess(element) {
    let { page } = element.dataset
    if (history.pushState) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set('page', page);
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }
}
