import ApplicationController from '../application_controller'

import { loadStripe } from '@stripe/stripe-js/pure'
loadStripe.setLoadParameters({ advancedFraudSignals: false })

export default class extends ApplicationController {
  async connect () {
    super.connect()

    this.stripe = await loadStripe('pk_test_3bwClDfs29v71djinatcBPVW00hSIY7YeW')
    let elements = this.stripe.elements()

    const style = {
      base: {
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }

    this.cardElement = elements.create('card', { style: style })
    this.cardElement.mount('#card-element')
  }

  createPaymentMethod(event) {
    let element = event.currentTarget
    let { clientSecret } = element.dataset
    let cardholderName = document.getElementById('cardholder-name')
    this.stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: this.cardElement,
        billing_details: {
          name: cardholderName.value,
        }
      }
    }).then((response) => {
      if (response.error) {
        console.log(response.error)
        // Display error message in your UI.
        // The card was declined (i.e. insufficient funds, card has expired, etc)
      } else {
        Cookies.set('payment_success', true)
        location.href = '/'
      }
    })
  }
}
