import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
  }

  addTag(event) {
    let element = event.currentTarget
    let tagsWrapper = element.closest('.tags-container')
    let otherTags = tagsWrapper.querySelectorAll('.tag.added')
    let ids = [...otherTags].map(tag => tag.dataset.tagId)
    ids.push(element.dataset.tagId)
    this.stimulate('FrontForm#update_tags', ids)
  }

  removeTag(event) {
    let element = event.currentTarget
    let tagsWrapper = element.closest('.tags-container')
    let otherTags = tagsWrapper.querySelectorAll('.tag.added')
    let ids = [...otherTags].map(tag => tag.dataset.tagId)
    let index = ids.indexOf(element.dataset.tagId)
    if (index > -1) {
      ids.splice(index, 1)
    }
    this.stimulate('FrontForm#update_tags', ids)
  }

  async submit(event) {
    event.preventDefault()
    event.stopPropagation()
    let form = event.currentTarget
    await this.stimulate('FrontForm#submit', form, { serializeForm: true })
    form.reset()
  }

  async submitNote(event) {
    event.preventDefault()
    event.stopPropagation()
    let form = event.currentTarget
    await this.stimulate('FrontForm#submit_note', form, { serializeForm: true })
    form.reset()
  }

  async submitLinkAndNote(event) {
    event.preventDefault()
    event.stopPropagation()
    let form = event.currentTarget
    await this.stimulate('FrontForm#submit_link_and_note', form, { serializeForm: true })
    form.reset()
  }

  serializeForm(form) {
    var obj = {}
    var formData = new FormData(form)
    for (var key of formData.keys()) {
      obj[key] = formData.get(key)
    }
    return JSON.stringify(obj)
  }

  showLinkNoteForm(event) {
    let urlInput = document.querySelector('input#link_url')
    let tagsWrapper = urlInput.parentElement.parentElement.querySelector('.tags-container')
    let otherTags = tagsWrapper.querySelectorAll('.tag.added')
    let ids = [...otherTags].map(tag => tag.dataset.tagId)
    this.stimulate('FrontForm#show_link_note_form', { url: urlInput.value, tag_ids: ids })
  }
}
