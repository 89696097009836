import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()
    this.enableExceptTags()
  }

  enableExceptTags() {
    let allTags = document.querySelector('input#all-tags')
    if(allTags.checked) {
      let checkboxWrapper = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper')
      checkboxWrapper.forEach((wrapper) => wrapper.classList.remove('disabled'))

      let inputs = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper input')
      inputs.forEach((input) => input.removeAttribute('disabled'))

      let labels = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper label')
      labels.forEach((label) => label.removeAttribute('disabled'))
    }
  }

  allTagsChanged(event) {
    let wrappers = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper')
    let inputs = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper input')
    let labels = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper label')

    if(event.currentTarget.checked) {
      let checkedTags = document.querySelectorAll('.edit_user .include-tags .checkbox-wrapper .regular-checkbox.user-defined:checked')
      checkedTags.forEach((input) => input.checked = false)

      wrappers.forEach((wrapper) => wrapper.classList.remove('disabled'))
      inputs.forEach((input) => input.removeAttribute('disabled'))
      labels.forEach((label) => label.removeAttribute('disabled'))
    } else {
      wrappers.forEach((wrapper) => wrapper.classList.add('disabled'))
      inputs.forEach((input) => input.setAttribute('disabled', ''))
      labels.forEach((label) => label.setAttribute('disabled', ''))
      inputs.forEach((input) => input.checked = false)

      let numberOfChecked = document.querySelectorAll('.edit_user .checkbox-wrapper .regular-checkbox.user-defined:checked').length
      if(numberOfChecked == 0) {
        event.target.checked = true
      }
    }
  }

  anyTagChecked(event) {
    let numberOfChecked = document.querySelectorAll('.edit_user .include-tags .checkbox-wrapper .regular-checkbox.user-defined:checked').length
    if(numberOfChecked > 0) {
      let allTags = document.querySelector('input#all-tags')
      allTags.checked = false
      let wrappers = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper')
      let inputs = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper input')
      let labels = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper label')
      wrappers.forEach((wrapper) => wrapper.classList.add('disabled'))
      inputs.forEach((input) => input.setAttribute('disabled', ''))
      labels.forEach((label) => label.setAttribute('disabled', ''))
      inputs.forEach((input) => input.checked = false)
    }
  }

  onSubmit(event) {
    let inputs = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper input')
    let labels = document.querySelectorAll('.edit_user .except-tags .checkbox-wrapper label')
    inputs.forEach((input) => input.removeAttribute('disabled'))
    labels.forEach((label) => label.removeAttribute('disabled'))
  }
}
