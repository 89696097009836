import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/scale.css'

export default class extends Controller {
  static targets = ['category'];

  connect() {
    super.connect()
    StimulusReflex.register(this);
    tippy('[data-tippy-content]', {
      theme: 'mailist',
      placement: 'left'
    });
    console.log('EXPLORE')
  }

  changeCategory(event) {
    let element = event.currentTarget;
    let parent = element.parentElement.parentElement
    const checkboxes = parent.querySelectorAll('input[name="category[]"]:checked');
    const categoryIds = [...checkboxes].map((checkbox) => checkbox.dataset.id);

    this.stimulate('Explore#change_category', element, categoryIds);
  }

  addLinkSuccess() {
    tippy(document.querySelectorAll('[data-tippy-content]')).forEach((i) => i.destroy())
    tippy('[data-tippy-content]', {
      theme: 'mailist',
      placement: 'left'
    });
  }

  removeBookmarkSuccess() {
    tippy(document.querySelectorAll('[data-tippy-content]')).forEach((i) => i.destroy())
    tippy('[data-tippy-content]', {
      theme: 'mailist',
      placement: 'left'
    });
  }
}
