import ApplicationController from './application_controller'
import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import * as clipboard from 'clipboard-polyfill/text'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/scale.css'

export default class extends ApplicationController {
  static targets = ['snippet']

  connect () {
    super.connect()
    document.addEventListener('stimulus-reflex:ready', this.connected.bind(this), { once: true })
  }

  connected() {
    this.initContentEditor()
  }

  initContentEditor() {
    let textarea = document.getElementById('content-editor')
    if(textarea) {
      tinymce.init({
        base_url: process.env.ASSET_PATH + 'tinymce',
        selector: '#content-editor',
        object_resizing: 'img',
        toolbar: 'image',
        file_picker_types: 'image',
        file_picker_callback: function(cb, value, meta) {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          input.onchange = function() {
            var file = this.files[0];
            var reader = new FileReader();

            reader.onload = function () {
              var id = 'blobid' + (new Date()).getTime();
              var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(',')[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              // call the callback and populate the Title field with the file name
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },

        content_css: process.env.ASSET_PATH + '/tinymce/skins/ui/oxide/content.min.css',
        menubar: false,
        height: 425,
        plugins: ['link', 'image'],
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify align | link image | viquestion',
        setup: (editor) => {
          const debouncedOnBodyInput = tinymce.util.Delay.debounce(this.onBodyInput.bind(this), 300)
          const debouncedOnBodyChange = tinymce.util.Delay.debounce(this.onBodyChange.bind(this), 300)
          editor.on('input', debouncedOnBodyInput)
          editor.on('change', debouncedOnBodyChange)
        }
      })
    }
  }

  onBodyInput(event) {
    let textarea = document.getElementById('content-editor')
    textarea.value = event.target.innerHTML
    textarea.dispatchEvent(new Event('input'))
  }

  onBodyChange(event) {
    let textarea = document.getElementById('content-editor')
    textarea.value = event.target.getContent()
    textarea.dispatchEvent(new Event('input'))
  }

  copySnippet(event) {
    this.selectText()
    clipboard.writeText(this.snippetTarget.innerHTML).then(
      () => {
        const tooltip = tippy(this.snippetTarget.parentNode, {
          theme: 'light',
          animation: 'scale',
          arrow: false,
          onHidden(instance) {
            instance.destroy()
          }
        })
        tooltip.setContent('📋 Copied!')
        tooltip.show()
      },
      () => { console.log("error!") }
    )

  }

  selectText() {
    var sel, range;
    var el = this.snippetTarget; //get element id
    if (window.getSelection && document.createRange) { //Browser compatibility
      sel = window.getSelection();
      if(sel.toString() == ''){ //no text selection
       window.setTimeout(function(){
        range = document.createRange(); //range object
        range.selectNodeContents(el); //sets Range
        sel.removeAllRanges(); //remove all ranges from selection
        sel.addRange(range);//add Range to a Selection.
      },1);
      }
    }else if (document.selection) { //older ie
      sel = document.selection.createRange();
      if(sel.text == ''){ //no text selection
        range = document.body.createTextRange();//Creates TextRange object
        range.moveToElementText(el);//sets Range
        range.select(); //make selection.
      }
    }
  }
}
