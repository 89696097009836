import ApplicationController from '../application_controller'
import Dropzone from "dropzone"
import * as csv from "csvtojson"

export default class extends ApplicationController {
  static targets = ["dropzone"]

  connect () {
    super.connect()
    this.dropzone = new Dropzone(this.dropzoneTarget, {
      autoQueue: false, // <- this is important!
      url: '/',
      clickable: true,
      uploadMultiple: false,
      previewsContainer: false,
      acceptedFiles: 'text/csv',
      addedfile: file => {
        var reader = new FileReader()
        reader.onload = (event) => {
          csv().fromString(event.target.result).then((contactsList) => {
            this.contactsList = contactsList
          })
          this.dropzoneTarget.innerHTML = file.name
        }
        reader.readAsText(file);
        // process upload here
      }
    })
  }

  upload() {
    if(this.contactsList == null) { return }
    this.stimulate('Subscriber#upload', this.contactsList)
  }
}
