import ApplicationController from './application_controller'

export default class extends ApplicationController {
  delete(event) {
    event.preventDefault()
    let element = event.currentTarget
    let { confirmation } = element.dataset
    if(confirm) {
      let response = window.confirm(confirmation)
      if(!response) { return }
    }
    this.stimulate('Bookmark#delete', element)
  }

  showTagsList(event) {
    let target = event.currentTarget
    target.classList.toggle('open-tags-list')
  }

  hideTagsList(event) {
    let target = event.currentTarget
    target.classList.remove('open-tags-list')
  }

  resetFiltersSuccess() {
    let searchBox = document.getElementById('search')
    let statusFilter = document.querySelector('.status-filter input[type=radio]')
    let tagFilters = document.querySelectorAll('.tag-filters input[type=checkbox]:checked')
    searchBox.value = ''
    statusFilter.checked = true
    tagFilters.forEach((filter) => filter.checked = false)
  }
}
