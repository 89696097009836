import ApplicationController from './application_controller'
import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'


export default class extends ApplicationController {
  connect () {
    super.connect()
    document.addEventListener('stimulus-reflex:ready', this.connected.bind(this), { once: true })
  }

  connected() {
    this.initHeadlineEditor()
    this.initFooterEditor()
  }

  initHeadlineEditor() {
    let textarea = document.getElementById('headline-editor')
    if(textarea) {
      tinymce.init({
        base_url: process.env.ASSET_PATH + 'tinymce',
        selector: '#headline-editor',
        content_css: process.env.ASSET_PATH + '/tinymce/skins/ui/oxide/content.min.css',
        menubar: false,
        height: 425,
        plugins: ['link', 'image'],
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify align | link image | viquestion',
        setup: (editor) => {
          const debouncedOnBodyInput = tinymce.util.Delay.debounce(this.onBodyInput.bind(this), 300)
          const debouncedOnBodyChange = tinymce.util.Delay.debounce(this.onBodyChange.bind(this), 300)
          editor.on('input', debouncedOnBodyInput)
          editor.on('change', debouncedOnBodyChange)
        }
      })
    }
  }

  initFooterEditor() {
    let textarea = document.getElementById('footer-editor')
    if(textarea) {
      tinymce.init({
        base_url: process.env.ASSET_PATH + 'tinymce',
        selector: '#footer-editor',
        content_css: process.env.ASSET_PATH + '/tinymce/skins/ui/oxide/content.min.css',
        menubar: false,
        height: 200,
        plugins: ['link'],
        toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify align | link image',
        setup: (editor) => {
          const debouncedOnLegalNoticeInput = tinymce.util.Delay.debounce(this.onLegalNoticeInput.bind(this), 300)
          const debouncedOnLegalNoticeChange = tinymce.util.Delay.debounce(this.onLegalNoticeChange.bind(this), 300)
          editor.on('input', debouncedOnLegalNoticeInput)
          editor.on('change', debouncedOnLegalNoticeChange)
        }
      })
    }
  }

  onBodyInput(event) {
    let textarea = document.getElementById('headline-editor')
    textarea.value = event.target.innerHTML
    textarea.dispatchEvent(new Event('input'))
  }

  onBodyChange(event) {
    let textarea = document.getElementById('headline-editor')
    textarea.value = event.target.getContent()
    textarea.dispatchEvent(new Event('input'))
  }

  onLegalNoticeInput(event) {
    let textarea = document.getElementById('footer-editor')
    textarea.value = event.target.innerHTML
    textarea.dispatchEvent(new Event('input'))
  }

  onLegalNoticeChange(event) {
    let textarea = document.getElementById('footer-editor')
    textarea.value = event.target.getContent()
    textarea.dispatchEvent(new Event('input'))
  }
}
