import ApplicationController from './application_controller'

export default class extends ApplicationController {
  delete(event) {
    event.preventDefault()
    let element = event.currentTarget
    let { confirmation } = element.dataset
    if(confirmation) {
      let response = window.confirm(confirmation)
      if(!response) { return }
    }
    this.stimulate('Subscriber#delete', element)
  }

  selectAll(event) {
    let checkboxes = document.querySelectorAll('.subscribers-list-item input[type=checkbox]')
    checkboxes.forEach((checkbox) => checkbox.checked = true)
    event.currentTarget.classList.add('hide')

    let unselectAllButton = document.querySelector('.unselect-all')
    unselectAllButton.classList.remove('hide')
  }

  unselectAll(event) {
    let checkboxes = document.querySelectorAll('.subscribers-list-item input[type=checkbox]')
    checkboxes.forEach((checkbox) => checkbox.checked = false)
    if(event) {
      event.currentTarget.classList.add('hide')
    }
    let selectAllButton = document.querySelector('.select-all')
    selectAllButton.classList.remove('hide')
  }

  deleteSelected(event) {
    event.preventDefault()
    let element = event.currentTarget
    let { confirmation } = element.dataset
    let checkboxes = document.querySelectorAll('.subscribers-list-item input[type=checkbox]:checked')
    if(checkboxes.length == 0) { return }
    if(confirm) {
      let response = window.confirm(confirmation)
      if(!response) { return }
    }
    let ids = [...checkboxes].map((checkbox) => checkbox.value)
    this.stimulate('Subscriber#delete_selected', element, ids)
  }
}
