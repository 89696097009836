import ApplicationController from './application_controller'

export default class extends ApplicationController {
  onChange(event) {
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]:checked')
    if(checkboxes.length == 0) {
      let mobileActions = document.getElementById('mobile-bulk-actions')
      mobileActions.classList.remove('opened')
    }
    if(checkboxes.length == 1) {
      let checkedBox = document.querySelector('.links-list-container input[type=checkbox]:checked')
      this.stimulate('Mobile#show_single_bookmark_actions', checkedBox)
    }
    if(checkboxes.length > 1) {
      this.stimulate('Mobile#show_multiple_bookmarks_actions', event.target)
    }
  }

  showSingleBookmarkActionsSuccess() {
    let mobileActions = document.getElementById('mobile-bulk-actions')
    mobileActions.classList.add('opened')
  }

  showMultipleBookmarksActionsSuccess() {
    let mobileActions = document.getElementById('mobile-bulk-actions')
    mobileActions.classList.add('opened')
  }

  toggleStatusSuccess() {
    this._unselectAll()
  }

  bulkToggleStatusSuccess() {
    this._unselectAll()
  }

  bulkAssignTagsSuccess() {
    this._unselectAll()
    let mobileActions = document.getElementById('mobile-bulk-actions')
    mobileActions.classList.remove('opened')
  }

  _unselectAll(event) {
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]')
    checkboxes.forEach((checkbox) => checkbox.checked = false)
  }

  bulkToggleStatus(event) {
    let element = event.currentTarget
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]:checked')
    if(checkboxes.length == 0) { return }
    let ids = [...checkboxes].map((checkbox) => checkbox.dataset.bookmarkId)
    this.stimulate('Mobile#bulk_toggle_status', element, ids)
  }

  showTagsList(event) {
    let target = event.currentTarget
    target.classList.toggle('open-tags-list')
  }

  hideTagsList(event) {
    let target = event.currentTarget
    target.classList.remove('open-tags-list')
  }

  assignTags(event) {
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]:checked')
    let ids = [...checkboxes].map((checkbox) => checkbox.dataset.bookmarkId)
    this.stimulate('Mobile#bulk_assign_tags', event.target, ids)
  }

  bulkDeleteLinks(event) {
    event.preventDefault()
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]:checked')
    if(checkboxes.length == 0) { return }
    let element = event.currentTarget
    let { confirmation } = element.dataset
    if(confirm) {
      let response = window.confirm(confirmation)
      if(!response) { return }
    }
    let ids = [...checkboxes].map((checkbox) => checkbox.dataset.bookmarkId)
    this.stimulate('Bookmark#bulk_delete', element, ids)
  }

  showMobileFilters(event) {
    let sidebar = document.querySelector('.sidebar')
    sidebar.classList.add('sidebar-opened')
    let mobileOverlay = document.querySelector('.sidebar .mobile-overlay')
    mobileOverlay.classList.remove('hide')
  }

  hideMobileFilters(event) {
    let sidebar = document.querySelector('.sidebar')
    sidebar.classList.remove('sidebar-opened')
    let mobileOverlay = document.querySelector('.sidebar .mobile-overlay')
    mobileOverlay.classList.add('hide')
  }

  showMobileNavOverlay(event) {
    let mobileNavOverlay = document.querySelector('.mobile-nav-overlay')
    mobileNavOverlay.classList.add('mobile-nav-opened')
  }

  hideMobileNavOverlay(event) {
    let mobileNavOverlay = document.querySelector('.mobile-nav-overlay')
    mobileNavOverlay.classList.remove('mobile-nav-opened')
  }
}
