import ApplicationController from './application_controller'

export default class extends ApplicationController {
  selectAll(event) {
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]')
    checkboxes.forEach((checkbox) => checkbox.checked = true)
    event.currentTarget.classList.add('hide')

    let unselectAllButton = document.querySelector('.unselect-all')
    unselectAllButton.classList.remove('hide')

    let actionsWrapper = document.querySelector('.actions-wrapper')
    actionsWrapper.classList.remove('hide')
  }

  unselectAll(event) {
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]')
    checkboxes.forEach((checkbox) => checkbox.checked = false)
    if(event) {
      event.currentTarget.classList.add('hide')
    }

    let selectAllButton = document.querySelector('.select-all')
    selectAllButton.classList.remove('hide')

    let actionsWrapper = document.querySelector('.actions-wrapper')
    actionsWrapper.classList.add('hide')
  }

  onChange(event) {
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]')
    let unselectAllButton = document.querySelector('.unselect-all')
    let selectAllButton = document.querySelector('.select-all')
    let allChecked = [...checkboxes].every((checkbox) => checkbox.checked)
    let anyChecked = [...checkboxes].some((checkbox) => checkbox.checked)
    let actionsWrapper = document.querySelector('.actions-wrapper')

    if(anyChecked) {
      actionsWrapper.classList.remove('hide')
    } else {
      actionsWrapper.classList.add('hide')
    }

    if(allChecked) {
      unselectAllButton.classList.remove('hide')
      selectAllButton.classList.add('hide')
    } else {
      unselectAllButton.classList.add('hide')
      selectAllButton.classList.remove('hide')
    }
  }

  assignTags(event) {
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]:checked')
    let ids = [...checkboxes].map((checkbox) => checkbox.dataset.bookmarkId)
    this.stimulate('Bookmark#bulk_assign_tags', event.target, ids)
  }

  bulkAssignTagsSuccess() {
    this.unselectAll()
  }

  bulkDeleteLinks(event) {
    event.preventDefault()
    let element = event.currentTarget
    let { confirmation } = element.dataset
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]:checked')
    if(checkboxes.length == 0) { return }
    if(confirm) {
      let response = window.confirm(confirmation)
      if(!response) { return }
    }
    let ids = [...checkboxes].map((checkbox) => checkbox.dataset.bookmarkId)
    this.stimulate('Bookmark#bulk_delete', element, ids)
  }

  bulkMarkRead(event) {
    let element = event.currentTarget
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]:checked')
    if(checkboxes.length == 0) { return }
    let ids = [...checkboxes].map((checkbox) => checkbox.dataset.bookmarkId)
    this.stimulate('Bookmark#bulk_mark_as_read', element, ids)
  }

  bulkMarkAsReadSuccess() {
    this.unselectAll()
  }

  bulkMarkNotRead(event) {
    let element = event.currentTarget
    let checkboxes = document.querySelectorAll('.links-list-container input[type=checkbox]:checked')
    if(checkboxes.length == 0) { return }
    let ids = [...checkboxes].map((checkbox) => checkbox.dataset.bookmarkId)
    this.stimulate('Bookmark#bulk_mark_as_not_read', element, ids)
  }

  bulkMarkAsNotReadSuccess() {
    this.unselectAll()
  }
}
