import ApplicationController from './application_controller'

export default class extends ApplicationController {
  applyFilters() {
    let searchBox = document.getElementById('search')
    let statusFilter = document.querySelector('.status-filter input[type=radio]:checked')
    let tagFilters = document.querySelectorAll('.tag-filters input[type=checkbox]:checked')
    let filters = {}
    filters.search = searchBox.value
    if(statusFilter.dataset.status != 'all') {
      filters.status = statusFilter.dataset.status
    }
    if(tagFilters.length > 0) {
      let ids = [...tagFilters].map((checkbox) => checkbox.value)
      filters.tags_ids = ids
    }
    this.stimulate('Bookmark#apply_filters', filters)
  }

  editTag(event) {
    let anchor = event.currentTarget
    anchor.classList.add('hide')
    let editInput = anchor.closest('.tag-checkbox-wrapper').querySelector('input.edit-tag')
    let value = editInput.value
    editInput.classList.remove('hide')
    editInput.value = ''
    editInput.focus()
    editInput.value = value
  }

  editTagKeyup(event) {
    if(event.key !== "Enter") { return }
    event.currentTarget.dispatchEvent(new Event('blur'))
  }

  deleteTag(event) {
    event.preventDefault()
    let element = event.currentTarget
    let { confirmation } = element.dataset
    if(confirm) {
      let response = window.confirm(confirmation)
      if(!response) { return }
    }
    this.stimulate('Tag#delete', element).then(() => {
      element.closest('.tag-checkbox-wrapper').remove()
    })
  }

  createTag(event) {
    let element = event.currentTarget
    let input = element.parentNode.querySelector('#create-tag')
    let searchBox = document.getElementById('search')
    let statusFilter = document.querySelector('.status-filter input[type=radio]:checked')
    let tagFilters = document.querySelectorAll('.tag-filters input[type=checkbox]:checked')
    let filters = {}
    if(input.value.trim() == "") { return }
    filters.search = searchBox.value
    if(statusFilter.dataset.status != 'all') {
      filters.status = statusFilter.dataset.status
    }
    if(tagFilters.length > 0) {
      let ids = [...tagFilters].map((checkbox) => checkbox.value)
      filters.tags_ids = ids
    }
    this.stimulate('Tag#create', input, filters).then(() => {
      input.value = ''
    })
  }

  blurTagInput(event) {
    let editInput = event.currentTarget
    editInput.classList.add('hide')
    let label = editInput.parentNode.querySelector('.tag-checkbox-description span')
    label.innerText = editInput.value
    label.classList.remove('hide')
  }

  collapseSidebar() {
    let sidebar = document.querySelector('.sidebar')
    let collapseIcon = document.querySelector('.collapse-icon')
    let collapseButton = document.querySelector('.collapse-button')
    let linksWrapper = document.querySelector('.links-container')
    sidebar.classList.toggle('collapsed')
    collapseButton.classList.toggle('button-collapsed')
    collapseIcon.classList.toggle('icon-collapsed')
    linksWrapper.classList.toggle('links-container-expanded')
  }
}
