import ApplicationController from './application_controller'
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-moment'
import moment from 'moment'
Chart.register(...registerables)

export default class extends ApplicationController {
  static targets = ['canvas']

  connect() {
    super.connect()
    let ticks
    fetch(this.data.get('url'), { headers: { accept: "application/json" } })
      .then(response => response.json())
      .then((response) => {
        ticks = response.map((entry) => entry.x)
        new Chart(this.canvasTarget, {
          type: 'line',
          data: {
            datasets: [{
              data: response,
              backgroundColor: '#644CFF',
              borderColor: '#3516FF',
              borderWidth: 1,
              cubicInterpolationMode: 'monotone'
            }]
          },
          options: {
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: true,
                text: this.data.get('title'),
                align: 'start',
                padding: { top: 0, bottom: 24 },
                color: '#B0B5CB',
                font: {
                  family: 'PT Sans',
                  weight: 'normal',
                  size: 16,
                }
              }
            },
            scales: {
              x: {
                grid: {
                  display: false
                },
                type: 'time',
                display: true,
                time: {
                  unit: 'day',
                  tooltipFormat: 'YYYY-MM-DD'
                },
                ticks: {
                  color: '#B0B5CB',
                  font: {
                    family: 'PT Sans'
                  },
                  callback: function(value, index, values) {
                    let date = moment(values[index].value).format('YYYY-MM-DD')
                    if(ticks.includes(date)) {
                      return value
                    }
                  },
                  minRotation: 45
                }
              },
              y: {
                beginAtZero: true,
                grid: {
                  display: false
                },
                ticks: {
                  stepSize: 1,
                  color: '#B0B5CB',
                  font: {
                    family: 'PT Sans'
                  }
                }
              }
            }
          }
        })
      })
  }
}
