import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/scale.css'

export default class extends Controller {
  connect() {
    super.connect()
    StimulusReflex.register(this);
    tippy('.nav-wrapper [data-tippy-content]', {
      theme: 'mailist',
      placement: 'bottom',
      showOnCreate: true,
      zIndex: 999999,
      onShow(instance) {
        setTimeout(() => {
          instance.hide();
        }, 5000);
      },
      onHidden(instance) {
        instance.destroy();
      }
    });
  }
}
