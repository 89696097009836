import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
  }

  close(event) {
    if(event.target.classList.contains('modal-backdrop')) {
      this.stimulate('Modal#close')
    }
  }
}
