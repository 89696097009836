import ApplicationController from './application_controller'

export default class extends ApplicationController {
  fileSelected(event) {
    let input = event.currentTarget
    let label = input.nextElementSibling
    let labelText = label.innerHTML
    var fileName = '';
    if( input.files && input.files.length > 1 )
      fileName = ( input.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', input.files.length );
    else
      fileName = input.value.split( '\\' ).pop();

    if( fileName )
      label.querySelector( 'span' ).innerHTML = fileName;
    else
      label.innerHTML = labelText;
  }

  removeLink(event) {
    let anchor = event.currentTarget
    let parent = anchor.closest('.links-list-item')
    parent.remove()
  }

  importAsReadChanged(event) {
    let input = event.currentTarget
    let linksStatuses = document.querySelectorAll('.import-preview .links-list-item input[name="link[links_attributes[][status]]"]')
    if(input.checked) {
      linksStatuses.forEach((input) => input.value = 'Read')
    } else {
      linksStatuses.forEach((input) => input.value = 'Not Read')
    }
  }
}
