import ApplicationController from '../application_controller'
import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/autolink'

import CableReady from 'cable_ready'
import consumer from '../../channels/consumer'

export default class extends ApplicationController {
  static values = { id: Number }

  connect () {
    super.connect()
    this.initBodyEditor()

    this.channel = consumer.subscriptions.create(
      {
        channel: 'PublicNewsletterChannel',
        id: this.idValue
      },
      {
        received (data) {
          if (data.cableReady) CableReady.perform(data.operations)
        }
      }
    )
  }

  disconnect() {
    this.channel.unsubscribe()
  }

  initBodyEditor() {
    tinymce.init({
      base_url: process.env.ASSET_PATH + 'tinymce',
      selector: '.bookmark-body',
      content_css: process.env.ASSET_PATH + '/tinymce/skins/ui/oxide/content.min.css',
      menubar: false,
      toolbar: [
        'undo redo | bold italic underline | numlist bullist outdent indent',
      ],
      plugins: [
        'link',
        'lists',
        'autolink'
      ],
      inline: true,
      setup: (editor) => {
        // const debouncedOnBodyInput = tinymce.util.Delay.debounce(this.onBodyInput.bind(this), 300)
        // const debouncedOnBodyChange = tinymce.util.Delay.debounce(this.onBodyChange.bind(this), 300)
        // editor.on('input', debouncedOnBodyInput)
        // editor.on('change', debouncedOnBodyChange)
        editor.on('blur', this.updateBookmark.bind(this, editor))
      }
    })
  }

  updateBookmark(editor) {
    this.stimulate('Bookmark#update_source', editor.targetElm, editor.targetElm.innerHTML)
  }
}
