import ApplicationController from './application_controller'
import * as clipboard from 'clipboard-polyfill/text'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/scale.css'

export default class extends ApplicationController {
  static targets = ['snippet']

  connect() {
    super.connect()
  }

  copySnippet(event) {
    this.selectText(this.snippetTarget.id)
    clipboard.writeText(this.snippetTarget.innerHTML).then(
      () => {
        const tooltip = tippy(this.snippetTarget.parentNode, {
          theme: 'light',
          animation: 'scale',
          arrow: false,
          onHidden(instance) {
            instance.destroy()
          }
        })
        tooltip.setContent('📋 Copied!')
        tooltip.show()
      },
      () => { console.log("error!") }
    )

  }

  selectText(id) {
    var sel, range;
    var el = document.getElementById(id); //get element id
    if (window.getSelection && document.createRange) { //Browser compatibility
      sel = window.getSelection();
      if(sel.toString() == ''){ //no text selection
       window.setTimeout(function(){
        range = document.createRange(); //range object
        range.selectNodeContents(el); //sets Range
        sel.removeAllRanges(); //remove all ranges from selection
        sel.addRange(range);//add Range to a Selection.
      },1);
      }
    }else if (document.selection) { //older ie
      sel = document.selection.createRange();
      if(sel.text == ''){ //no text selection
        range = document.body.createTextRange();//Creates TextRange object
        range.moveToElementText(el);//sets Range
        range.select(); //make selection.
      }
    }
  }
}
